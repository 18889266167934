.under-construction {
  text-align: center;
  padding: 50px;
}

.under-construction h1 {
  color: #007bff;
}

.under-construction input[type="email"] {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.under-construction button {
  margin-left: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
